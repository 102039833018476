import { applyMiddleware, combineReducers, createStore } from 'redux';
import applePayMerchantRegistration from '@engine/reducers/apple-pay-merchant-registration.reducer';
import blacklist from '@engine/reducers/blacklist.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import contractFormReducer from '@engine/reducers/contract-form/contract-form.reducer';
import declinedReasons from '@app/components/reducers/declined-reasons.reducer';
import emailEntities from '@engine/reducers/email/entity.reducer';
import flashMessage from '@engine/reducers/flash-message.reducer';
import issuingLoadOperations from '@app/components/reducers/issuing-load-operations.reducer';
import merchantCredentials from '@engine/reducers/merchant-credentials.reducer';
import merchantRemindersConfig from '@engine/reducers/merchant-reminders-config.reducer';
import merchantWpfThemes from '@engine/reducers/merchant-wpf-themes.reducer';
import modal from '@engine/reducers/modal.reducer';
import payByLinkForm from '@engine/reducers/pay-by-link-form.reducer';
import payPalMerchantRegistration from '@engine/reducers/pay-pal-merchant-registration.reducer';
import resellerTree from '@app/components/reducers/reseller-tree.reducer';
import riskFilterSettings from '@engine/reducers/risk-filter-settings.reducer';
import serviceStatus from '@app/components/reducers/service-status.reducer';
import sessionTime from '@engine/reducers/sessionTime';
import showWpfFields from '@engine/reducers/show_wpf_fields.reducer';
import testDataForm from '@app/components/reducers/test-data-form.reducer';
import thunk from 'redux-thunk';
import virtualTerminalFormData from '@engine/reducers/virtual-terminal-form-data.reducer';
import virtualTerminalState from '@engine/reducers/virtual-terminal-state.reducer';
import volumesForSelection from '@engine/reducers/volumes-for-selection.reducer';
import wpfCustomThemeV2 from '@engine/reducers/v2/wpf-custom-theme.reducer';
import wpfTopLevelBankCodes from '@engine/reducers/wpf_top_level_bank_codes.reducer';

const middleware = [thunk];

/**
 * List of the Reducers that will always be loaded in the application store
 */

const staticReducers = {
  applePayMerchantRegistration,
  blacklist,
  entities: emailEntities,
  declinedReasons,
  merchantWpfThemes,
  modal,
  payPalMerchantRegistration,
  resellerTree,
  riskFilterSettings,
  serviceStatus,
  sessionTime,
  showWpfFields,
  volumesForSelection,
  wpfTopLevelBankCodes,
  issuingLoadOperations,
  flashMessage,
  merchantCredentials,
  payByLinkForm,
  virtualTerminalFormData,
  virtualTerminalState,
  wpfCustomThemeV2,
  contractFormReducer,
  merchantRemindersConfig,
  testDataForm
};

/**
 * Create Reducer with the static reducers and the async ones.
 * In this way, we make sure that the static reducers will always present
 * when new reducer is injected.
 */

const createReducer = (asyncReducers) =>
  combineReducers({
    ...staticReducers,
    ...asyncReducers
  });

/**
 * Initialize the store
 */

const configureStore = () => {
  const store = createStore(
    createReducer(),
    composeWithDevTools(
      applyMiddleware(...middleware)
    )
  );

  store.asyncReducers = {};

  /**
   * Provide reusable injectReducer() function that keeps references
   * to all of the existing reducers. /thanks to createReducer()/
   */

  store.injectReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));

    return store;
  };

  return store;
};

const store = configureStore();

export default store;
